import React from "react";
import Image from "next/image";
import Form from "./Form";
import { LadyContact } from "@/assets/images";

function Signup() {
  return (
    <section className="bg-white" id="signup">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="relative flex items-end px-4 pb-10 pt-60 sm:pb-16 md:justify-center lg:pb-24 bg-gray-50 sm:px-6 lg:px-8">
          <div className="absolute inset-0">
            <Image src={LadyContact} alt="" layout="fill" objectFit="cover" className="object-cover" />
          </div>
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />

          <div className="relative">
            <div className="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-8 xl:max-w-xl">
              <h3 className="text-3xl font-bold text-white">
                Join Leading CRE Capital Advisors &
                <br className="hidden xl:block" />
                Take Your Deal-Making to the Next Level
              </h3>
            </div>
          </div>
        </div>

        <div className="px-4 py-10 bg-white sm:px-6 lg:px-20 sm:py-16 lg:py-24">
          <div className="xl:w-full xl:max-w-sm 2xl:max-w-3xl xl:mx-auto">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl">Try FREE for 30 days</h2>
            <p className="mt-2 text-base text-gray-600">
              Already have an account?{" "}
              <a
                href="/login"
                title="Login"
                className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
              >
                Login
              </a>
            </p>

            <Form />

            <p className="mt-5 text-sm text-gray-600">
              This site is protected by reCAPTCHA and the Google{" "}
              <a
                href="https://policies.google.com/privacy"
                title="Privacy Policy"
                target="_blank"
                className="text-blue-600 transition-all duration-200 hover:underline hover:text-blue-700"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              &{" "}
              <a
                href="https://policies.google.com/terms"
                title="Terms of Service"
                target="_blank"
                className="text-blue-600 transition-all duration-200 hover:underline hover:text-blue-700"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
