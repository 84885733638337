import React, { Fragment, memo } from "react";
import { featuresList } from "./data";
import { SimpleCheck, Cancel } from "@/assets/icons";

function MobileVersion({ headers }) {
  return (
    <div className="block mt-12 sm:hidden">
      <div>
        <div className="grid grid-cols-3 text-center">
          {headers.map(header => (
            <div key={header.label} className="px-2 py-4">
              <h2 className="font-bold text-gray-200 font-pj font-lg">{header}</h2>
            </div>
          ))}
        </div>

        {featuresList.map(category => (
          <Fragment key={category}>
            <div className="px-2 pt-9 pb-4 sm:px-4">
              <p className="text-xl font-black">{category.category}</p>
            </div>
            {category.features.map(feature => (
              <Fragment key={feature.label}>
                <div className="px-2 py-4 sm:px-4">
                  <p className="font-medium">{feature.label}</p>
                </div>
                <div className="grid grid-cols-3 text-center">
                  {feature.rows.map(row => (
                    <div key={row.label} className="px-2 py-2 flex justify-center items-center">
                      {row.label === "Check" ? (
                        <SimpleCheck className="size-8 text-gray-900" />
                      ) : (
                        <Cancel className="size-8 text-gray-900" />
                      )}
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          </Fragment>
        ))}

        <div className="grid grid-cols-3 text-center">
          {headers.map(header => (
            <div key={header.label} className="px-1 py-4 sm:px-4">
              <h2 className="font-bold text-white font-pj font-lg">{header}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

MobileVersion.propTypes = {
  headers: Array.isRequired,
};

export default memo(MobileVersion);
