export const inputs = [
  {
    id: "firstName",
    label: "First Name",
    placeholder: "First Name",
    colSpan: 1,
  },
  {
    id: "lastName",
    label: "Last Name",
    placeholder: "Last Name",
    colSpan: 1,
  },
  {
    id: "email",
    label: "Email",
    placeholder: "Email",
    type: "email",
    colSpan: 2,
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
    placeholder: "(999) 999 999",
    type: "tel",
    colSpan: 2,
  },
  {
    id: "company",
    label: "Company",
    placeholder: "Company",
    colSpan: 2,
  },
  {
    id: "website",
    label: "Company Site",
    placeholder: "www.site.com or site.com",
    colSpan: 2,
  },
  {
    id: "referrerName",
    label: "Referred By",
    placeholder: "Referred By",
    colSpan: 1,
  },
  {
    id: "referrerEmail",
    label: "Referred Email",
    placeholder: "Referred Email",
    colSpan: 1,
  },
];
