import React from "react";
import { NextSeo } from "next-seo";
import { useUser } from "@auth0/nextjs-auth0/client";
import HowItWorks from "src/features/LandingPage/WhyFundview";
import Navbar from "@/features/LandingPage/Navbar";
import Hero from "@/features/LandingPage/Hero";
import Content from "@/features/LandingPage/Content";
import FeatureDataTable from "@/features/LandingPage/FeatureDataTable";
import Signup from "@/features/LandingPage/Signup";
import FAQ from "@/features/LandingPage/FAQ";
import Footer from "@/features/LandingPage/Footer";
import Pricing from "@/features/LandingPage/Pricing";
import useNotifier from "@/hooks/useNotifier";
import { Loading } from "@/components/loading/loading";
import UseCases from "@/features/LandingPage/UseCases";

function Home() {
  const { user, isLoading } = useUser();
  useNotifier();

  return (
    <>
      <NextSeo
        title="Fundview"
        description="Deal flow software designed for CRE loan brokers."
        canonical="https://www.fundview.com/"
        openGraph={{
          url: "https://www.fundview.com/",
          title: "Fundview",
          description: "Deal flow software designed for CRE loan brokers.",
          type: "website",
          images: [
            {
              url: "https://www.fundview.com/fv_seo_icon.png",
              width: 585,
              height: 593,
              alt: "Fundview Logo",
              type: "image/png",
            },
          ],
          siteName: "Fundview",
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="overflow-x-hidden bg-gray-50">
            <Navbar user={user} />
            <Hero />
          </div>

          <Content />
          <HowItWorks />
          <UseCases />
          <FeatureDataTable />
          <Pricing />
          <Signup />
          <FAQ />
          <Footer />
        </>
      )}
    </>
  );
}

export default Home;
