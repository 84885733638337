import Image from "next/image";
import React from "react";
import { LinkedIn } from "@/assets/icons";
import YouTube from "@/assets/icons/Youtube";
import { Fundview } from "@/assets/images";

const socialMedia = [
  {
    icon: YouTube,
    label: "YouTube",
    link: "https://www.youtube.com/@fundview_CREdealflow",
  },
  {
    icon: LinkedIn,
    label: "LinkedIn",
    link: "https://www.linkedin.com/company/fundview",
  },
];

const options = [
  {
    label: "Home",
    link: "/",
    target: "_self",
  },
  {
    label: "Google API Disclosure",
    link: "https://www.fundview.com/blog/google-api-disclosure",
    target: "_blank",
  },
  {
    label: "Privacy",
    link: "https://www.fundview.com/policy",
    target: "_blank",
  },
  {
    label: "Contact",
    link: "mailto:support@fundview.com",
    target: "_blank",
  },
];

function Footer() {
  return (
    <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
          <div className="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
            <Image className="w-auto h-9" src={Fundview} alt="Logo" />

            <p className="text-base leading-relaxed text-gray-600 mt-7">
              Fundview is a powerful CRM for brokers, streamlining client management, deal tracking, and workflows.
              Boost productivity and drive growth with our intuitive solution—your partner in brokerage success.
            </p>

            <ul className="flex items-center space-x-3 mt-9">
              {socialMedia.map(item => (
                <li key={item.label}>
                  <a href={item.link} title="" className="flex items-center justify-center size-7">
                    <item.icon className="w-auto h-9 transition-all duration-200 hover:text-blue-800" />
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">Company</p>

            <ul className="mt-6 space-y-4">
              {options.map(item => (
                <li key={item.label}>
                  <a
                    href={item.link}
                    title={item.label}
                    target={item.target}
                    className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
