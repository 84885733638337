import React from "react";
import { features } from "./data";
import Item from "./Item";

function UseCases() {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20" id="useCases">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center sm:mx-auto lg:max-w-2xl">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Use Cases</h2>
          <p className="mt-4 text-lg text-gray-700 md:max-w-md md:mx-auto sm:mt-6 font-pj">
            Here is how Fundview can help you.
          </p>
        </div>

        <div className="relative mt-12 md:mt-16 ">
          <div className="absolute -inset-4 lg:-inset-y-6 lg:-inset-x-8">
            <div
              className="w-full h-full max-w-4xl mx-auto opacity-20 blur-lg filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            />
          </div>

          <div className="relative mx-auto grid grid-cols-1 sm:grid-cols-3 gap-8">
            {features.map(feature => (
              <Item key={feature.title} {...feature} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default UseCases;
