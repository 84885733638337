import Image from "next/image";
import React from "react";
import { Dashboard } from "@/assets/images";

function Hero() {
  return (
    <section className="pt-28 sm:pt-60">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="px-6 text-lg text-gray-600 font-inter">
            Smart CRE Finance Platform, made for Capital Advisors
          </h1>
          <p className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj">
            Revolutionizing the CRE Capital Advisory
            <span className="relative inline-flex sm:inline">
              <span className="bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg filter opacity-30 w-full h-full absolute inset-0" />
              <span className="relative"> Business </span>
            </span>
          </p>

          <div className="px-8 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9">
            <a
              href="#signup"
              title=""
              className="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              role="button"
            >
              Get more deals closed
            </a>
          </div>

          <p className="mt-8 text-base text-gray-500 font-inter">30 Days free trial · No credit card required</p>
        </div>
      </div>

      <div className="mt-20 pb-32">
        <div className="relative">
          <div className="absolute inset-0 h-2/3 bg-gray-50" />
          <div className="relative mx-auto">
            <div className="lg:max-w-6xl lg:mx-auto">
              <Image className="transform scale-110" src={Dashboard} alt="Illustration" height={800} priority />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
