export const featuresList = [
  {
    category: "Lender Efficiencies",
    features: [
      {
        label: "Real-Time Lender Parameter Updates",
        popoverText: "Users can request lenders to update programs in real-time, instantly updating in the system",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No built-in lender database; all updates are manual.",
          },
          {
            label: "Cancel",
            popoverText: "May offer lender data but not real-time updates.",
          },
        ],
      },
      {
        label: "Lender Heat Map & Best-Fit Matching",
        popoverText:
          "Automatically matches managed loans against your lender parameters to quickly identify best-fit lenders and prepare loan packages",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No lender matching functionality.",
          },
          {
            label: "Cancel",
            popoverText: "Some platforms offer lender data but lack real-time comparative analysis.",
          },
        ],
      },
      {
        label: "Lender & Borrower Database",
        popoverText: "Centralized repository with real-time lender updates.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Requires manual setup and lacks CRE-specific intelligence.",
          },
          {
            label: "Cancel",
            popoverText: "May include lender data but often static or outdated.",
          },
        ],
      },
      {
        label: "Advanced Filtering & Keyword Search for Lenders",
        popoverText:
          "Sort & filter your lenders by criteria such as loan size, asset type, and terms; quickly search with keywords",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No lender filtering; must manually track and search contacts.",
          },
          {
            label: "Cancel",
            popoverText: "Some platforms allow filtering, but not always robust or user-friendly.",
          },
        ],
      },
    ],
  },
  {
    category: "Borrower Efficiencies",
    features: [
      {
        label: "Borrower Portal & Custom Needs List",
        popoverText: "Automated borrower checklist for document collection and real-time status tracking.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No borrower portal; manual document requests.",
          },
          {
            label: "Cancel",
            popoverText: "May have borrower portals, but often lack dynamic tracking.",
          },
        ],
      },
      {
        label: "Loan Summary Generation & Document Attachment",
        popoverText:
          "Quickly generate a loan summary with key details and attach supporting documents for seamless sharing.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No built-in feature; requires manual document creation and attachment.",
          },
          {
            label: "Cancel",
            popoverText: "Some platforms allow document attachments but lack instant summary generation.",
          },
        ],
      },
      {
        label: "Automated Loan Process Management",
        popoverText: "End-to-end loan lifecycle tracking, including borrower & lender interactions.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Requires custom workflows, often inefficient for financing needs.",
          },
          {
            label: "Cancel",
            popoverText: "Some automation, but often lacks full process visibility.",
          },
        ],
      },
      {
        label: "Referral Database & Updates",
        popoverText:
          "Built-in referral database with real-time loan status updates, automatically notifying borrowers and referral partners as milestones progress.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No structured referral tracking; requires manual updates and borrower follow-ups.",
          },
          {
            label: "Cancel",
            popoverText: "Some systems may track referrals, but lack automated loan status notifications.",
          },
        ],
      },
    ],
  },
  {
    category: "Internal Efficiencies (Broker & Team Workflow)",
    features: [
      {
        label: "Messaging Hub (Internal & External Communication)",
        popoverText:
          "Centralized messaging hub for full team collaboration; your lenders can be included in discussions, but internal team chats can opt to stay private",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "No built-in messaging; users must rely on email or third-party tools.",
          },
          {
            label: "Cancel",
            popoverText:
              "Some messaging features, but often lack the ability to separate internal vs. external conversations.",
          },
        ],
      },
      {
        label: "Pipeline & Deal Tracking",
        popoverText: "CRE transaction-focused with lender & borrower tracking.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Basic sales pipeline, lacks CRE finance-specific stages.",
          },
          {
            label: "Cancel",
            popoverText:
              "Deal tracking exists but often lacks full financing cycle visibility for borrowers and lenders.",
          },
        ],
      },
      {
        label: "Collaboration & Workflow",
        popoverText: "CRE-specific workflows for your team, lenders & clients",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "General collaboration tools, not built for CRE financing.",
          },
          {
            label: "Cancel",
            popoverText: "Some collaboration tools, but not optimized for multi-party deals.",
          },
        ],
      },
      {
        label: "Custom Team Collaboration & Role-Based Access",
        popoverText:
          "Create teams within the platform, assigning roles to control access to contacts, deals & lender data.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Basic collaboration features, but lacks deal-specific access control.",
          },
          {
            label: "Cancel",
            popoverText: "Some platforms allow role-based access, but often lack lender-specific restrictions.",
          },
        ],
      },
      {
        label: "Automated Notifications & Reminders",
        popoverText: "Auto alerts for outstanding borrower items, new borrower documents, and overdue loan closings.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Generic reminders, no borrower-specific automation.",
          },
          {
            label: "Cancel",
            popoverText: "Some systems offer alerts, but not tailored for CRE finance workflows.",
          },
        ],
      },
    ],
  },
  {
    category: "Administrative & Cost Efficiencies",
    features: [
      {
        label: "Industry-Specific Customization",
        popoverText: "Purpose-built for CRE finance workflows.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Requires extensive customization.",
          },
          {
            label: "Cancel",
            popoverText: "Partially tailored but often rigid and limited.",
          },
        ],
      },
      {
        label: "Document Management",
        popoverText: "Built for loan docs, term sheets, and financial statements with easy tagging.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Basic document storage, no CRE-specific organization.",
          },
          {
            label: "Cancel",
            popoverText: "Some platforms offer doc storage, but often lack automated tagging.",
          },
        ],
      },
      {
        label: "No Data or Entry Caps – No Additional Fees",
        popoverText: "Unlimited data, deals, and entries without additional costs.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Many CRMs charge for more data, users, or storage.",
          },
          {
            label: "Cancel",
            popoverText: "Some CRE software may cap entries and charge for exceeding limits.",
          },
        ],
      },
      {
        label: "Mobile Optimization",
        popoverText:
          "Fully optimized for mobile, allowing your team, lenders, and borrowers to manage deals from anywhere",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Limited mobile functionality; often desktop-first design.",
          },
          {
            label: "Cancel",
            popoverText: "Some platforms support mobile, but often clunky or missing key features.",
          },
        ],
      },
      {
        label: "All Features Included – No Add-Ons",
        popoverText: "Every feature available in the suite, no additional costs for 'premium' tools.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Many essential features are gated behind paid add-ons.",
          },
          {
            label: "Cancel",
            popoverText: "Often requires extra modules or higher-tier pricing for full functionality.",
          },
        ],
      },
      {
        label: "Ease of Use for CRE Finance Advisors",
        popoverText: "Intuitive interface built CRE finance intermediaries",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Generic UI that requires adaptation.",
          },
          {
            label: "Cancel",
            popoverText: "Often complex, outdated, or difficult to use.",
          },
        ],
      },
      {
        label: "Onboarding & Support",
        popoverText: "CRE finance-expert support & industry training.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "General support teams unfamiliar with CRE finance.",
          },
          {
            label: "Cancel",
            popoverText: "Some industry support, but may lack deep advisory expertise.",
          },
        ],
      },
      {
        label: "Ready to Use Immediately – No Customization Required",
        popoverText: "Start implementing features with your borrowers & lenders on Day 1; no setup delays",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "Requires heavy customization before use.",
          },
          {
            label: "Cancel",
            popoverText: "Often requires weeks or months of setup before full functionality.",
          },
        ],
      },
      {
        label: "Fees & Commission",
        popoverText: "No additional fees or commission splits – full access with transparent pricing.",
        rows: [
          { label: "Check" },
          {
            label: "Cancel",
            popoverText: "May charge per user, customization, or API access.",
          },
          {
            label: "Cancel",
            popoverText:
              "Some platforms charge per transaction, take a percentage of deals, or require high licensing fees.",
          },
        ],
      },
    ],
  },
];
