import React from "react";
import PropTypes from "prop-types";
import { Check } from "@/assets/icons";

function Item({ title, description }) {
  return (
    <div className="bg-white rounded-xl mt-5 sm:mt-0">
      <div className="px-6 py-8">
        <div className="flex items-start">
          <Check className="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" />
          <div className="ml-5">
            <h3 className="text-lg font-bold text-gray-900 font-pj">{title}</h3>
            <p className="mt-3 text-base text-gray-900 font-pj">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

Item.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default React.memo(Item);
