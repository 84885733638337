import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";

function NavLink({ href, label, className, role, target = "_self" }) {
  return (
    <Link href={href} legacyBehavior>
      <a className={className} role={role} target={target}>
        {label}
      </a>
    </Link>
  );
}

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  role: PropTypes.string,
  target: PropTypes.string,
};

export default NavLink;
