import React, { memo } from "react";
import PropTypes from "prop-types";
import htmlToReact from "html-to-react";

const htmlParser = new htmlToReact.Parser();

const Item = memo(({ item, active, toggleAccordion }) => (
  <div className="relative">
    <div className="absolute -inset-1">
      <div
        className="w-full h-full mx-auto opacity-30 blur-lg filter"
        style={{
          background:
            "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
        }}
      />
    </div>

    <div className="relative overflow-hidden bg-white border border-gray-200 rounded-xl">
      <h3>
        <button
          type="button"
          onClick={() => toggleAccordion(item.id)}
          aria-expanded={active === item.id}
          className="flex items-center justify-between w-full px-6 py-5 text-xl font-bold text-left text-gray-900 sm:p-8 font-pj"
        >
          <span>{item.question}</span>
          <span className="ml-4">
            {active === item.id ? (
              <svg
                className="w-6 h-6 text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
              </svg>
            )}
          </span>
        </button>
      </h3>

      {active === item.id && <div className="px-6 pb-6 sm:px-8 sm:pb-8">{htmlParser.parse(item.answer)}</div>}
    </div>
  </div>
));

Item.propTypes = {
  item: PropTypes.object,
  active: PropTypes.string,
  toggleAccordion: PropTypes.func,
};

export default Item;
