import Image from "next/image";
import React from "react";
import { HowItWorks } from "@/assets/images";
import { features } from "@/features/LandingPage/WhyFundview/data";

function Index() {
  return (
    <section id="whyFundview">
      <div className="relative bg-blue-600">
        <div className="absolute top-0 left-0 w-full h-full">
          <Image
            src="https://landingfoliocom.imgix.net/store/collection/saasui/images/how-it-works/3/ring-pattern.svg"
            alt=""
            fill
            className="object-cover opacity-20 2xl:opacity-100"
          />
        </div>

        <div className="h-56 bg-gray-900 sm:h-72 md:absolute md:right-0 md:h-full md:w-1/2 relative">
          <Image src={HowItWorks} alt="" fill className="object-cover" />
        </div>

        <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 sm:py-16 lg:py-20 xl:py-24">
          <div className="md:mr-auto md:w-1/2 md:pr-10">
            <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl lg:text-5xl">Why Fundview?</h2>
            <p className="mt-4 text-base font-normal leading-7 text-blue-300 lg:text-lg lg:mt-6 lg:leading-8">
              A Technology Assistant That Understands the Nuances of CRE Finance Dealflow.
            </p>
          </div>
        </div>
      </div>

      <div className="py-12 bg-white sm:py-16 lg:py-20 xl:py-20">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 gap-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:gap-y-16">
            {features.map(feature => {
              const Icon = feature.icon;
              return (
                <div key={feature.title}>
                  <div className="relative flex items-center justify-center mx-auto">
                    <Icon className="w-auto h-20" />
                  </div>
                  <h3 className="mt-8 text-lg font-semibold text-black">{feature.title}</h3>
                  <p className="mt-4 text-base text-gray-600">{feature.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
