export const features = [
  {
    title: "Simplifying Loan Origination & Lender Submission Tracking",
    description:
      "A CRE capital advisor leverages Fundview to manage the entire deal lifecycle from intake to closing within a single platform. With automated borrower checklists and lender portals, all document transfers and status updates are seamlessly organized in one centralized hub.",
  },
  {
    title: "Optimizing Lender Matching for Faster Closings",
    description:
      "An advisor needs to quickly identify the best-fit lenders for a complex financing request. Using Fundview’s Lender Heat Map & Best-Fit Matching, they instantly compare lender criteria and assemble loan packages efficiently.",
  },
  {
    title: "Automating Document Collection & Loan Packaging",
    description:
      "Fundview’s automated borrower portal eliminates the need to chase down documents. The system generates requests and tracks submissions in real time, ensuring a seamless collection process. Advisors can then instantly compile complete loan summaries with all supporting documents.",
  },
  {
    title: "Managing Team Collaboration & Deal Access",
    description:
      "A mortgage brokerage firm with multiple team members assigns role-based access within Fundview, ensuring that each team member only sees the relevant contacts, deals, and lender data, improving security and workflow efficiency.",
  },
  {
    title: "Minimizing Administrative Work & Maximizing Efficiency",
    description:
      "A CRE advisor once spent hours responding to both borrowers and lenders on status inquiries and tracking down documents. With Fundview’s automated notifications and integrated portals, they eliminate unnecessary back-and-forth, ensuring smoother communication and more time to focus on closing deals.",
  },
  {
    title: "Enhancing Referral Partner Engagement",
    description:
      "Instead of manually updating referral sources, an advisor loops in referral contacts through Fundview, ensuring they receive automatic status updates. This transparency strengthens relationships and encourages more referrals.",
  },
];
