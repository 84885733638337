import Image from "next/image";
import React from "react";
import { Why } from "@/assets/images";

const imageSize = 500;

function Content() {
  return (
    <section className="py-10 bg-gray-800 lg:py-0" id="revolutionizing">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid items-stretch grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-12 xl:gap-x-24">
          <div className="h-full pr-12 lg:order-2 lg:mb-40 flex justify-center items-center">
            <div className="relative h-full lg:h-auto">
              <div className="absolute w-full h-[100%] sm:h-[720px] mt-10 -mb-12 overflow-hidden bg-gradient-to-r from-fuchsia-600 to-blue-600 top-12 left-12 xl:left-16 lg:top-0 lg:scale-y-105 lg:origin-top">
                <Image
                  className="object-cover object-right w-full h-full scale-150"
                  src="/images/lines.svg"
                  alt=""
                  width={imageSize}
                  height={imageSize}
                />
              </div>
              <div className="relative">
                <Image src={Why} alt="" width={imageSize} height={imageSize} />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start py-10 lg:order-1 sm:py-16 lg:py-24 xl:py-48">
            <div>
              <h2 className="mt-8 text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl lg:leading-tight">
                More Than a CRM—Your All-in-One Deal Flow Platform
              </h2>
              <p className="text-xl leading-relaxed text-gray-200 mt-9">
                Closing just one or two more deals can turn a good year into a great one. The key? Efficiency and
                organization.
              </p>
              <p className="mt-6 text-xl leading-relaxed text-gray-200">
                Fundview isn’t just another CRM—it’s a fully integrated deal flow platform built exclusively for capital
                market advisors. While CRMs focus on contact management, Fundview streamlines the entire financing
                process by integrating your borrowers and lenders through secure portals, automating key workflows, and
                organizing every step from intake to closing.
              </p>
              <p className="mt-6 text-xl leading-relaxed text-gray-200">
                Tired of software that bypasses your role as an intermediary or platforms that wants to eat into your
                commissions? Unlike generic CRMs, Fundview is built for the way you work, eliminating inefficiencies and
                giving you the tools to manage every stage of the deal flow—without costly customizations or extra
                development.
              </p>
              <p className="mt-6 text-xl leading-relaxed text-gray-200">
                With the most cost-effective pricing in the market, Fundview maximizes your operational ROI, helping you
                scale efficiently while keeping overhead low.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
