import React from "react";

function LoanPackaging(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      id="FolderSecurity"
      x="0"
      y="0"
      enableBackground="new 0 0 68 68"
      version="1.1"
      viewBox="0 0 68 68"
    >
      <path
        fill="#f90f00"
        d="M56.2350044,26.4629822v16.7661743c0,1.1783333-0.9554329,2.1337128-2.133709,2.1337128H13.8987064\n\t\t\t\tc-1.1782761,0-2.1337118-0.9553795-2.1337118-2.1337128V21.6265926c0-1.1783333,0.9554358-2.1337109,2.1337118-2.1337109\n\t\t\t\tH25.667345c0.6424732,0,1.2494106,0.2892876,1.6548061,0.7870598l2.650528,3.2598381\n\t\t\t\tc0.4053955,0.4978886,1.0123329,0.7871761,1.654808,0.7871761l22.4738083,0.0023155\n\t\t\t\tC55.2795715,24.3292713,56.2350044,25.2823334,56.2350044,26.4629822z"
        className="colorf9ac00 svgShape"
      />
      <path
        fill="#f3ffe9"
        d="M52.2801857,12.1232443v29.0027237c0,1.0053253-0.8265839,1.8320923-1.8320885,1.8320923H17.5519047\n\t\t\t\t\t\tc-1.0055046,0-1.8320913-0.826767-1.8320913-1.8320923V3.8264658c0-1.0055048,0.8265867-1.8248186,1.8320913-1.8248186\n\t\t\t\t\t\th24.5994129L52.2801857,12.1232443z"
        className="colore9e9ff svgShape"
      />
      <path
        fill="#26d7fe"
        d="M52.2779655 12.1246452H43.979805c-1.0053635 0-1.8277702-.8224077-1.8277702-1.8277721V1.9987144L52.2779655 12.1246452zM21.1741695 19.3352528h11.1783352c.0985832 0 .1785049.0799198.1785049.1785069v1.0440636c0 .098587-.0799217.1785049-.1785049.1785049H21.1741695c-.0985851 0-.1785049-.0799179-.1785049-.1785049v-1.0440636C20.9956646 19.4151726 21.0755844 19.3352528 21.1741695 19.3352528zM44.1624184 19.5124073v1.0441856c0 .0980549-.0801888.1786785-.1784592.1786785h-8.6715546c-.0980568 0-.178463-.0806236-.178463-.1786785v-1.0441856c0-.0980568.0804062-.1786804.178463-.1786804h8.6715546C44.0822296 19.3337269 44.1624184 19.4143505 44.1624184 19.5124073zM44.1624184 23.2214069v1.0441875c0 .0980549-.0801888.1782436-.1784592.1782436H29.3901176c-.0982742 0-.178463-.0801888-.178463-.1782436v-1.0441875c0-.0980549.0801888-.1786785.178463-.1786785h14.5938416C44.0822296 23.0427284 44.1624184 23.1233521 44.1624184 23.2214069zM21.1741695 23.0416718h5.2591667c.0985851 0 .1785049.0799179.1785049.1785049v1.0440636c0 .098587-.0799198.1785069-.1785049.1785069h-5.2591667c-.0985851 0-.1785049-.0799198-.1785049-.1785069v-1.0440636C20.9956646 23.1215897 21.0755844 23.0416718 21.1741695 23.0416718z"
        className="colorcfcfff svgShape"
      />
      <g fill="#000000" className="color000000 svgShape">
        <path
          fill="#9ae45f"
          d="M39.9425697,12.2364626v3.0980988c0,0.3139133-0.2531052,0.5670214-0.5670204,0.5670214h-15.368885\n\t\t\t\t\tc-0.3136654,0-0.5667744-0.253108-0.5667744-0.5670214v-3.0980988c0-0.3139133,0.253109-0.5670214,0.5667744-0.5670214\n\t\t\t\t\th15.368885C39.6894646,11.6694412,39.9425697,11.9225492,39.9425697,12.2364626z"
          className="color7bd681 svgShape"
        />
      </g>
      <g fill="#000000" className="color000000 svgShape">
        <path
          fill="#8afd2e"
          d="M43.076828,28.8003426c-0.7633858,0-1.5044785,0.2573605-2.1035538,0.7305031l-0.3384361,0.2672901\n\t\t\t\tl-1.591423,1.2563286c-0.2696686,0.2049198-0.5768204,0.363039-0.9026031,0.4280148\n\t\t\t\tc-0.1860619,0.0558872-0.3721275,0.0840569-0.5675049,0.0840569H14.1329117\n\t\t\t\tc-1.3029003,0-2.3636227,1.0604973-2.3636227,2.3727112V52.615593c0,1.3049889,1.0677195,2.3727112,2.3727102,2.3727112\n\t\t\t\th39.7162285c1.3049927,0,2.3727112-1.0677223,2.3727112-2.3727112V29.630928\n\t\t\t\tc0-0.2935219-0.0592957-0.5715942-0.1572113-0.8305855H43.076828z"
          className="colorfdc72e svgShape"
        />
      </g>
      <g fill="#000000" className="color000000 svgShape">
        <path
          fill="#8afd2e"
          d="M56.230938,29.630928V52.615593c0,1.3026733-1.0702629,2.3727112-2.3729362,2.3727112H14.1419992\n\t\t\t\tc-1.3026733,0-2.3727102-1.0700378-2.3727102-2.3727112V33.9392471c0-1.3122139,1.0607224-2.3727112,2.3636227-2.3727112\n\t\t\t\tH37.573307c0.1953773,0,0.381443-0.0281696,0.5675049-0.0840569c0.3257828-0.0649757,0.6329346-0.2230949,0.9026031-0.4280148\n\t\t\t\tl1.591423-1.2563286l0.3348694-0.2698956c0.3257828-0.2603512,0.6979103-0.446188,1.0886688-0.5675049\n\t\t\t\tc0.325779-0.102232,0.6701927-0.1581192,1.0143738-0.1581192h12.9998398\n\t\t\t\tC56.1750526,29.0629673,56.230938,29.3328629,56.230938,29.630928z"
          className="colorfdc72e svgShape"
        />
      </g>
      <g opacity=".2" fill="#000000" className="color000000 svgShape">
        <path
          fill="#ffffff"
          d="M50.8242722,54.9883041H32.9296646l-7.9000969-23.4217682H37.573307\n\t\t\t\t\tc0.1953773,0,0.381443-0.0281696,0.5675049-0.0840569c0.3257828-0.0649757,0.6329346-0.2230949,0.9026031-0.4280148\n\t\t\t\t\tl1.591423-1.2563286l0.3348694-0.2698956c0.3257828-0.2603512,0.6979103-0.446188,1.0886688-0.5675049L50.8242722,54.9883041z"
          className="colorffffff svgShape"
        />
        <polygon
          fill="#ffffff"
          points="29.878 54.988 25.718 54.988 17.846 31.567 22.005 31.567"
          className="colorffffff svgShape"
        />
      </g>
      <g fill="#000000" className="color000000 svgShape">
        <path
          fill="#d85b53"
          d="M46.0157967,41.0067673v10.3231354c0,3.1387444-1.0113564,6.2156982-2.9772568,8.6658325\n\t\t\t\t\tc-0.0759315,0.094944-0.1518669,0.189888-0.2325516,0.2801971c-0.4702034,0.565033-0.9877052,1.0967941-1.543232,1.5953979\n\t\t\t\t\tc-0.4654503,0.4226723-0.9640541,0.821579-1.4863091,1.1966057c-1.5289688,1.1111717-3.2906075,2.0465889-5.2517586,2.830143\n\t\t\t\t\tc-0.3323631,0.1376114-0.7170143,0.1376114-1.0541306,0c-0.0332718-0.0143738-0.0616722-0.0237656-0.094944-0.0380249\n\t\t\t\t\tc-3.5090179-1.4197617-6.3819389-3.3238792-8.4189072-5.8643188c-1.9611492-2.4501343-2.9725037-5.5270882-2.9725037-8.6658325\n\t\t\t\t\tV41.0067673c0-0.6028214,0.3797798-1.1395683,0.9449272-1.3436012l1.462429-0.5223694l6.5528164-2.3315392l1.6809521-0.6030579\n\t\t\t\t\tl0.8973961-0.3181038c0.3087158-0.113842,0.6458359-0.113842,0.9545517,0l0.1423569,0.052166l10.4512367,3.7229042\n\t\t\t\t\tC45.6360168,39.8671989,46.0157967,40.4039459,46.0157967,41.0067673z"
          className="colored524e svgShape"
        />
        <path
          fill="#f3ffe9"
          d="M34.1789894,62.93964c-0.1133652,0.047226-0.2453117,0.047226-0.3586731,0\n\t\t\t\t\tc-2.9901562-1.2456589-5.2120934-2.8079453-6.7760258-4.7608757c-1.5175915-1.8934135-2.3530884-4.3378181-2.3530884-6.8835678\n\t\t\t\t\tV42.423542c0-0.1916885,0.1205292-0.3626747,0.3010674-0.4271011l8.8549671-3.159996\n\t\t\t\t\tc0.098568-0.0351715,0.2062645-0.0351715,0.3048325,0l8.8549652,3.159996\n\t\t\t\t\tc0.1805382,0.0644264,0.3010674,0.2354126,0.3010674,0.4271011v8.8716545c0,2.5459633-0.835495,4.9903679-2.3526611,6.8829308\n\t\t\t\t\tC39.3910904,60.1316795,37.1691437,61.6939812,34.1789894,62.93964z"
          className="colore9e9ff svgShape"
        />
        <path
          fill="#26d7fe"
          d="M43.3077698,42.4219398v0.9479294c0-0.195076-0.1180229-0.3629189-0.2993698-0.4308968\n\t\t\t\t\tl-8.8565178-3.1562157c-0.0997505-0.0363159-0.2040443-0.0363159-0.3037949,0l-8.8565178,3.1562157\n\t\t\t\t\tc-0.1813488,0.0679779-0.2992573,0.2358208-0.2992573,0.4308968v-0.9479294\n\t\t\t\t\tc0-0.1904259,0.1179085-0.3626938,0.2992573-0.4262466l8.8565178-3.1606445\n\t\t\t\t\tc0.0997505-0.0318832,0.2040443-0.0318832,0.3037949,0L43.0084,41.9956932\n\t\t\t\t\tC43.1897469,42.0592461,43.3077698,42.231514,43.3077698,42.4219398z"
          className="colorcfcfff svgShape"
        />
      </g>
      <g fill="#000000" className="color000000 svgShape">
        <path
          fill="#d45985"
          d="M36.4240875,55.2963486h-4.8559017l0.7191067-5.2696342\n\t\t\tc-0.699976-0.6045761-1.0755234-1.5974388-0.8272285-2.6476364c0.2353954-0.9100456,0.9673805-1.6546135,1.8774261-1.8774261\n\t\t\tc1.7438049-0.4327278,3.2902794,0.8783493,3.2902794,2.5457077c0,0.8019028-0.3500671,1.5084038-0.91642,1.9793549\n\t\t\tL36.4240875,55.2963486z"
          className="color4f5d99 svgShape"
        />
      </g>
    </svg>
  );
}

export default React.memo(LoanPackaging);
