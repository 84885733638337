import React from "react";

function User(props) {
  return (
    <svg
      {...props}
      className="w-5 h-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7 7m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
      />
    </svg>
  );
}

export default React.memo(User);
