import React from "react";
import DesktopVersion from "./DesktopVersion";
import MobileVersion from "./MobileVersion";

const headers = ["Fundview", "Off-the-Shelf CRMs", "Other CRE Finance Software"];

function FeatureDataTable() {
  return (
    <section className="relative py-12 sm:py-16 lg:py-20" id="features">
      <div className="absolute inset-0">
        <div className="relative h-full w-full bg-gray-900">
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]" />
        </div>
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 text-gray-200">
        <div className="text-center">
          <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">Features</h2>
          <p className="max-w-xl mx-auto mt-6 text-base font-normal text-gray-400 font-pj sm:whitespace-nowrap">
            Below you can find an extensive list of Fundview features with many more in the pipeline.
          </p>
        </div>

        <MobileVersion headers={headers} />
        <DesktopVersion headers={headers} />
      </div>
    </section>
  );
}

export default FeatureDataTable;
