import React, { useState } from "react";
import Item from "./Item";
import { faqItems } from "./data";

const defaultActive = faqItems[0].id;

function FAQ() {
  const [active, setActive] = useState(defaultActive);

  const toggleAccordion = id => {
    setActive(prev => (prev === id ? defaultActive : id));
  };

  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20" id="faq">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
            Fundview FAQ – Everything You Need to Know
          </h2>
        </div>

        <div className="max-w-5xl mx-auto mt-8 sm:mt-14">
          <div className="space-y-4">
            {faqItems.map(item => (
              <Item key={item.id} item={item} active={active} toggleAccordion={toggleAccordion} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
