import React from "react";
import PropTypes from "prop-types";

function Popover({ text, widthClass = "w-auto" }) {
  return (
    <div
      className={`absolute bottom-10 left-1/2 transform -translate-x-1/2 text-gray-800 bg-white text-lg font-bold rounded-lg px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10 pointer-events-none group-hover:pointer-events-auto ${widthClass}`}
    >
      {text}
      <div className="absolute left-1/2 bottom-[-6px] transform -translate-x-1/2 w-3 h-3 bg-white rotate-45" />
    </div>
  );
}

Popover.propTypes = {
  text: PropTypes.string.isRequired,
  widthClass: PropTypes.string,
};

export default React.memo(Popover);
