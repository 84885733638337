export const faqItems = [
  {
    id: 1,
    question: "What is Fundview?",
    answer:
      "Fundview is a powerful deal flow platform designed specifically for CRE capital advisors. Unlike generic CRMs, it streamlines the entire financing process—from intake to closing—by integrating borrowers, lenders, and deal teams through smart automation and collaboration tools.",
  },
  {
    id: 2,
    question: "How is Fundview different from a traditional CRM?",
    answer:
      "While CRMs focus on contact management, Fundview is a fully integrated deal flow solution that helps you:\n\n- Automate borrower and lender interactions.\n- Track term sheets, soft quotes, and lending parameters.\n- Manage needs lists and document requests with no limits.\n- Send deal packages to multiple lenders in one click.",
  },
  {
    id: 3,
    question: "Who is Fundview designed for?",
    answer:
      "Fundview is built for capital market advisors, commercial mortgage brokers, and debt & equity professionals who need a more efficient way to manage their lender relationships and close more deals.",
  },
  {
    id: 4,
    question: "Does Fundview have any limits on deal entries or document requests?",
    answer:
      "No! Fundview offers unlimited deal entries, needs list document requests, team members, term sheet tracking, and automated notifications—all for one simple price.",
  },
  {
    id: 5,
    question: "How does Fundview help with lender matching?",
    answer:
      'Our SmartSort Technology does more than just filter lenders that check every "box"—it gives you deeper insights.\n\nFundview instantly ranks your lenders based on how well your loan details compare to their deal parameters, highlighting both matches and mismatches. This helps you:\n\n- Identify lenders that are the best fit while understanding potential gaps in criteria.\n- Spot missing or outdated data before reaching out.\n- Ensure no opportunities slip through the cracks, even if a lender isn’t a perfect match.\n\nWith SmartSort, you make smarter, more informed decisions.',
  },
  {
    id: 6,
    question: "Can I collaborate with my team inside Fundview?",
    answer:
      "Yes! Fundview allows unlimited team members to collaborate, but with controlled access and role-based permissions. You can:\n\n- Assign custom roles to team members, limiting access to certain information.\n- Control which loans team members can view or manage.\n- Restrict access to sensitive lender contact details, ensuring privacy while maintaining efficiency.\n\nThis flexibility allows you to scale your team without compromising data security or control.",
  },
  {
    id: 7,
    question: "Does Fundview support borrower and lender portals?",
    answer:
      "Absolutely! Borrowers and lenders can securely upload documents, track outstanding items, and receive notifications—keeping deals moving efficiently.",
  },
  {
    id: 8,
    question: "How much does Fundview cost?",
    answer:
      "Fundview offers the most cost-effective pricing in the market with no hidden fees or expensive add-ons. Guaranteed! If you find a comparable software for a better price, we will give you access for FREE*",
  },
  {
    id: 9,
    question: "Is there a free trial or demo available?",
    answer:
      "Enjoy free, 30-day access to explore Fundview’s full capabilities—no credit card required, no risk. Once you sign up, a team member will follow up via email to assist you, and you'll have the opportunity to schedule a personalized demo at your convenience.",
  },
  {
    id: 10,
    question: "Do I need to install any software?",
    answer:
      "No! Fundview is cloud-based, meaning you can access it anytime, anywhere without downloads or complicated setups.",
  },
  {
    id: 11,
    question: "What kind of support does Fundview offer?",
    answer:
      "We provide dedicated technical and customer support, ensuring you get the help you need whenever you need it.",
  },
  {
    id: 12,
    question: "How do I sign up?",
    answer:
      "Getting started is easy! <a className='font-bold text-blue-600' href='#signup'>Fill out the form</a> for your free account today.",
  },
];
