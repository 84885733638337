import React from "react";

function HeatMap(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="HeatMap">
      <defs>
        <linearGradient id="a" x1="339.33" x2="411.02" y1="333.91" y2="333.91" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#cacadd" className="stopColorcacfdd svgShape" />
          <stop offset="1" stopColor="#53d86a" className="stopColoreef0f1 svgShape" />
        </linearGradient>
      </defs>
      <g fill="#167ffc" className="color000000 svgShape">
        <rect width="223.01" height="223.01" x="188.02" y="146.75" fill="#f5f5f5" className="colorf5f5f5 svgShape" />
        <rect width="55.75" height="55.75" x="355.27" y="258.25" fill="#a9a9c4" className="colora9afc4 svgShape" />
        <rect width="341.83" height="28.44" x="69.2" y="118.31" fill="#595bd4" className="color5e6175 svgShape" />
        <rect width="118.82" height="55.75" x="69.2" y="146.75" fill="#f5f5f5" className="colorf5f5f5 svgShape" />
        <rect width="118.82" height="55.75" x="69.2" y="202.5" fill="#f5f5f5" className="colorf5f5f5 svgShape" />
        <rect width="118.82" height="55.75" x="69.2" y="258.25" fill="#f5f5f5" className="colorf5f5f5 svgShape" />
        <rect width="118.82" height="55.75" x="69.2" y="314" fill="#f5f5f5" className="colorf5f5f5 svgShape" />
        <path
          fill="#595bd4"
          d="M414,149.75H66.2V115.31H414Zm-341.82-6H408V121.31H72.2Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M191,205.5H66.2V143.75H191Zm-118.82-6H185V149.75H72.2Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M191,261.25H66.2V199.5H191Zm-118.82-6H185V205.5H72.2Z"
          className="color5e6175 svgShape"
        />
        <path fill="#595bd4" d="M191,317H66.2V255.25H191ZM72.2,311H185V261.25H72.2Z" className="color5e6175 svgShape" />
        <path fill="#595bd4" d="M191,372.75H66.2V311H191Zm-118.82-6H185V317H72.2Z" className="color5e6175 svgShape" />
        <path fill="#595bd4" d="M414,372.75H185v-229H414Zm-223-6H408v-217H191Z" className="color5e6175 svgShape" />
        <rect width="55.75" height="55.75" x="188.02" y="146.75" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="243.77" y="146.75" fill="#fe9526" className="colore1830e svgShape" />
        <rect width="55.75" height="55.75" x="355.27" y="146.75" fill="#a9a9c4" className="colora9afc4 svgShape" />
        <rect width="55.75" height="55.75" x="188.02" y="202.5" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="243.77" y="202.5" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="299.52" y="202.5" fill="#a9a9c4" className="colora9afc4 svgShape" />
        <rect width="55.75" height="55.75" x="355.27" y="202.5" fill="#fe9526" className="colore1830e svgShape" />
        <rect width="55.75" height="55.75" x="188.02" y="258.25" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="243.77" y="258.25" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="299.52" y="258.25" fill="#fe9526" className="colore1830e svgShape" />
        <rect width="55.75" height="55.75" x="188.02" y="314" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="243.77" y="314" fill="#fe9526" className="colore1830e svgShape" />
        <rect width="55.75" height="55.75" x="299.52" y="314" fill="#a9a9c4" className="colora9afc4 svgShape" />
        <rect width="55.75" height="55.75" x="355.27" y="314" fill="#a9a9c4" className="colora9afc4 svgShape" />
        <rect width="68.43" height="11.47" x="94.39" y="168.89" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="68.43" height="11.47" x="94.39" y="224.64" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="68.43" height="11.47" x="94.39" y="280.39" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="68.43" height="11.47" x="94.39" y="336.67" fill="#dedeec" className="colordee1ec svgShape" />
        <rect width="55.75" height="55.75" x="299.52" y="146.75" fill="#dedeec" className="colordee1ec svgShape" />
        <path
          fill="#595bd4"
          d="M246.77,205.5H185V143.75h61.75Zm-55.75-6h49.75V149.75H191Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M302.52 205.5H240.77V143.75h61.75zm-55.75-6h49.75V149.75H246.77zM414 205.5H352.27V143.75H414zm-55.75-6H408V149.75H358.27z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M246.77,261.25H185V199.5h61.75Zm-55.75-6h49.75V205.5H191Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M302.52,261.25H240.77V199.5h61.75Zm-55.75-6h49.75V205.5H246.77Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M358.27,261.25H296.52V199.5h61.75Zm-55.75-6h49.75V205.5H302.52Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M414 261.25H352.27V199.5H414zm-55.75-6H408V205.5H358.27zM246.77 317H185V255.25h61.75zM191 311h49.75V261.25H191z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M302.52,317H240.77V255.25h61.75Zm-55.75-6h49.75V261.25H246.77Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M358.27,317H296.52V255.25h61.75Zm-55.75-6h49.75V261.25H302.52Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M414 317H352.27V255.25H414zm-55.75-6H408V261.25H358.27zM246.77 372.75H185V311h61.75zm-55.75-6h49.75V317H191z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M302.52 372.75H240.77V311h61.75zm-55.75-6h49.75V317H246.77zM358.27 205.5H296.52V143.75h61.75zm-55.75-6h49.75V149.75H302.52zM390.86 87.06h0a11.35 11.35 0 1120.24 5.49A11.34 11.34 0 01390.86 87.06zm5.95-.77a5.35 5.35 0 104.62-6 5.36 5.36 0 00-4.62 6zM446.19 127.13a8.8 8.8 0 118.73-9.91A8.81 8.81 0 01446.19 127.13zm0-11.59a2.81 2.81 0 00-2.78 3.15h0a2.8 2.8 0 102.78-3.15z"
          className="color5e6175 svgShape"
        />
        <rect
          width="14.71"
          height="6"
          x="46.26"
          y="382.68"
          fill="#595bd4"
          transform="rotate(-45 53.623 385.679)"
          className="color5e6175 svgShape"
        />
        <rect width="6" height="14.71" x="67.95" y="388.19" fill="#595bd4" className="color5e6175 svgShape" />
        <rect width="14.71" height="6" x="36" y="366.24" fill="#595bd4" className="color5e6175 svgShape" />
        <path
          fill="#a9a9c4"
          d="M53.71,135.22h-6v-6h6Zm0-16h-6c0-5.6,0-5.62,0-6.11l6,.22C53.71,113.74,53.71,113.6,53.71,119.22Zm2.78-13.32-4.67-3.77a18.55,18.55,0,0,1,5.43-4.58l2.93,5.24A12.54,12.54,0,0,0,56.49,105.9Zm108.06-4.7h-.18v-6h.18Zm-10.18,0h-6v-6h6Zm-16,0h-6v-6h6Zm-16,0h-6v-6h6Zm-16,0h-6v-6h6Zm-16,0h-6v-6h6Zm-16,0h-6v-6h6Z"
          className="colora9afc4 svgShape"
        />
        <path
          fill="url(#a)"
          d="M411,298.07v71.68H339.33A76.57,76.57,0,0,1,411,298.07Z"
          style={{ mixBlendMode: "multiply" }}
        />
        <path
          fill="#595bd4"
          d="M358.27,372.75H296.52V311h61.75Zm-55.75-6h49.75V317H302.52Z"
          className="color5e6175 svgShape"
        />
        <path
          fill="#595bd4"
          d="M414,372.75H352.27V311H414Zm-55.75-6H408V317H358.27Z"
          className="color5e6175 svgShape"
        />
        <circle cx="415.63" cy="374.37" r="60.37" fill="#f5f5f5" className="colorf5f5f5 svgShape" />
        <path
          fill="#595bd4"
          d="M415.63,437.73A63.37,63.37,0,1,1,479,374.37,63.43,63.43,0,0,1,415.63,437.73Zm0-120.73A57.37,57.37,0,1,0,473,374.37,57.43,57.43,0,0,0,415.63,317Z"
          className="color5e6175 svgShape"
        />
        <polygon
          fill="#fe9526"
          points="404.76 402.15 381.55 378.94 390.69 369.8 404.76 383.87 437.47 351.16 446.61 360.3 404.76 402.15"
          className="colore1830e svgShape"
        />
      </g>
    </svg>
  );
}

export default React.memo(HeatMap);
