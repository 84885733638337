import React from "react";
import PropTypes from "prop-types";
import { Check } from "@/assets/icons";

function Item({ title }) {
  return (
    <div className="flex items-center">
      <div className="inline-flex items-center justify-center flex-shrink-0 size-10 bg-white">
        <Check className="size-7 text-gray-900" />
      </div>
      <div className="ml-4">
        <p className="text-lg font-bold text-gray-900 font-pj">{title}</p>
      </div>
    </div>
  );
}

Item.propTypes = {
  title: PropTypes.string,
};

export default React.memo(Item);
