import React from "react";

function SecureCollaboration(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="Secure">
      <g fill="#167ffc" className="color000000 svgShape">
        <rect width="55.16" height="42.43" x="3.75" y="4" fill="#595bd4" rx="3.33" className="color909ba5 svgShape" />
        <path fill="#e7f1e9" d="M24.97 46.43h12.72v8.87H24.97z" className="colore7ecf1 svgShape" />
        <path
          fill="#f6f9f6"
          d="M17.7 55.3H45a2.35 2.35 0 0 1 0 4.7H17.7a2.35 2.35 0 0 1 0-4.7Z"
          className="colorf6f9f9 svgShape"
        />
        <path fill="#53d86a" d="M28.53 46.43v3.28a2.8 2.8 0 1 0 5.6 0v-3.28Z" className="colordae4ea svgShape" />
        <rect width="49.32" height="32.6" x="6.67" y="6.92" fill="#f6f9f6" rx=".41" className="colorf6f9f9 svgShape" />
        <path
          fill="#fe9526"
          d="M20.49 13a6 6 0 0 0-6 6v4h3v-4a2.95 2.95 0 0 1 5.89 0v4h3v-4a6 6 0 0 0-5.89-6zm16.37 13.6h10.51a4 4 0 1 1 0 7.91H36.86a4 4 0 0 1 0-7.91z"
          className="colorecb780 svgShape"
        />
        <path
          fill="#dd9c57"
          fillRule="evenodd"
          d="M29.31 24.51A1.49 1.49 0 0 0 27.82 23H13.16a1.49 1.49 0 0 0-1.48 1.49v15h17.63Z"
          className="colordd5790 svgShape"
        />
        <path
          fill="#fe9526"
          d="M23 31.27a2.48 2.48 0 1 1-2.48-2.48A2.47 2.47 0 0 1 23 31.27Z"
          className="colorecb780 svgShape"
        />
        <path
          fill="#fe9526"
          fillRule="evenodd"
          d="M19.49 33.74v1.7a1 1 0 0 0 2 0v-1.7a1 1 0 0 0-2 0Z"
          className="colorecb780 svgShape"
        />
        <path
          fill="#595bd4"
          fillRule="evenodd"
          d="M35.63 16.67h8a1 1 0 0 0 0-2h-8a1 1 0 1 0 0 2zm0 3.54h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2zm0 3.53h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2zm12.72-9.07H47a1 1 0 1 0 0 2h1.37a1 1 0 0 0 0-2zm0 3.54H47a1 1 0 0 0 0 2h1.37a1 1 0 0 0 0-2zm0 3.53H47a1 1 0 0 0 0 2h1.37a1 1 0 0 0 0-2z"
          className="color909ba5 svgShape"
        />
      </g>
    </svg>
  );
}

export default React.memo(SecureCollaboration);
