import React from "react";

function CostEffective(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" id="RevenueGrowth">
      <path
        fill="#167ffc"
        d="m69.97 5.059-.027 6 8.695.039-18.411 17.769-22.981-11.935L5 33.327l2.72 5.349 29.497-14.998L61.29 36.179l21.584-20.83-.035 8.709 6 .025.076-18.94z"
        className="color4080ff svgShape"
      />
      <path
        fill="#53d86a"
        d="M81.277 86.478 62.24 74.196l-2.12 2.843 17.042 14.902zM32.264 36.144C20.249 38.597 12.469 50.367 14.92 62.383c2.453 12.014 14.226 19.795 26.238 17.344C53.172 77.274 60.952 65.504 58.5 53.488c-2.452-12.014-14.221-19.793-26.236-17.344z"
        className="colorffffff svgShape"
      />
      <path
        fill="#fe9526"
        d="M11.669 42.272v15.631c.006-5.785 2.034-11.274 5.524-15.631h-5.524zm.508 20.671a25.046 25.046 0 0 1-.508-5.04v7.083H5v5h9.784a24.92 24.92 0 0 1-2.607-7.043zm76.497 2.042v-33.64h-13.72v33.64h-6.337V41.176H55.298a24.89 24.89 0 0 1 5.946 11.753c1.234 6.046.156 12.032-2.611 17.056H95v-5h-6.326z"
        className="colorb3ccff svgShape"
      />
      <path
        fill="#167ffc"
        d="M45.165 62.577c0-5.249-4.553-6.369-7.566-7.11-3.273-.806-3.739-1.188-3.739-2.179 0-.227.051-.805.522-1.289.504-.516 1.38-.789 2.535-.789h.004c1.124 0 3.74.203 3.74 2.081h4.5c0-2.556-1.541-5.465-5.655-6.323l.003-2.813-4.5-.004-.003 2.725c-1.893.355-3.111 1.229-3.845 1.98a6.392 6.392 0 0 0-1.802 4.432c0 4.786 4.311 5.847 7.163 6.549 3.444.848 4.141 1.309 4.141 2.745.001.757-.227 1.073-.399 1.246-.529.53-1.746.834-3.342.834h-.007c-1.126 0-2.034-.288-2.557-.81-.332-.332-.5-.76-.5-1.274h-4.5c0 1.703.646 3.286 1.82 4.458.981.98 2.305 1.638 3.828 1.938l-.002 2.743 4.5.004.002-2.794c1.651-.316 2.977-.953 3.943-1.921 1.126-1.127 1.719-2.66 1.716-4.429z"
        className="color4080ff svgShape"
      />
      <path
        fill="#595bd4"
        d="M84.237 85.967a2.396 2.396 0 0 0-1.048-1.587L63.542 71.704a2.504 2.504 0 0 0-3.377.591l-.461.619-1.944-1.45c3.409-5.304 4.841-11.885 3.484-18.535-2.762-13.528-16.018-22.293-29.54-19.528-13.527 2.761-22.287 16.014-19.527 29.542 2.418 11.846 12.88 20.036 24.528 20.036 1.653 0 3.332-.165 5.013-.508a24.923 24.923 0 0 0 14.403-8.735l1.91 1.424-.315.423-.001.001a2.515 2.515 0 0 0 .37 3.395l17.599 15.39a2.388 2.388 0 0 0 1.802.561 2.38 2.38 0 0 0 1.637-.937l4.667-6.195a2.394 2.394 0 0 0 .447-1.831zm-43.079-6.24c-12.012 2.452-23.785-5.33-26.238-17.344-2.451-12.016 5.329-23.786 17.344-26.239 12.015-2.45 23.784 5.33 26.237 17.344 2.451 12.016-5.329 23.786-17.343 26.239zm36.004 12.214L60.12 77.039l2.12-2.843 19.037 12.282-4.115 5.463z"
        className="color494949 svgShape"
      />
    </svg>
  );
}

export default React.memo(CostEffective);
