import React, { Fragment, memo } from "react";
import { featuresList } from "../data";
import Row from "./Row";
import TableHeader from "./TableHeader";

function DesktopVersion({ headers }) {
  return (
    <div className="hidden mt-16 sm:block">
      <table className="w-full max-w-7xl mx-auto lg:table-fixed font-pj">
        <TableHeader headers={headers} />

        <tbody>
          {featuresList.map(({ category, features }) => (
            <Fragment key={category}>
              <tr className="divide-x divide-gray-200">
                <td className="pt-9 pr-4 text-xl font-black" colSpan={2}>
                  {category}
                </td>
              </tr>

              {features.map(feature => (
                <Row key={feature} feature={feature} headers={headers} />
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

DesktopVersion.propTypes = {
  headers: Array.isRequired,
};

export default memo(DesktopVersion);
