import React from "react";
import PropTypes from "prop-types";

const desktopLineTransforms = [
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 11)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 46)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 81)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 116)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 151)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 18)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 53)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 88)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 123)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 158)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 25)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 60)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 95)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 130)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 165)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 32)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 67)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 102)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 137)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 172)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 39)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 74)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 109)",
  "matrix(-0.83205 -0.5547 -0.5547 0.83205 15 144)",
];

const mobileLineTransforms = [
  "matrix(-0.5547 0.83205 0.83205 0.5547 11 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 46 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 81 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 116 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 151 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 18 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 53 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 88 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 123 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 158 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 25 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 60 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 95 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 130 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 165 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 32 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 67 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 102 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 137 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 172 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 39 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 74 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 109 1)",
  "matrix(-0.5547 0.83205 0.83205 0.5547 144 1)",
];

function SeparatorSVG({ mobile }) {
  const transforms = mobile ? mobileLineTransforms : desktopLineTransforms;
  const svgProps = mobile
    ? { className: "w-auto h-4 mx-auto text-gray-300", viewBox: "0 0 172 16" }
    : { className: "w-4 h-auto mx-auto text-gray-300", viewBox: "0 0 16 172" };

  return (
    <svg {...svgProps} fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      {transforms.map(t => (
        <line key={t} y1="-0.5" x2="18.0278" y2="-0.5" transform={t} />
      ))}
    </svg>
  );
}

SeparatorSVG.propTypes = {
  mobile: PropTypes.bool,
};

export default React.memo(SeparatorSVG);
