import React from "react";
import PropTypes from "prop-types";
import { Cancel, Question, SimpleCheck } from "@/assets/icons";
import Popover from "@/components/popover/Popover";

function Row({ feature, headers }) {
  return (
    <>
      <tr className="hover:bg-gray-700">
        <td className="py-4 px-4 font-medium" colSpan={2} title={feature.popoverText}>
          <div className="flex items-center">
            <p className="mr-4">{feature.label}</p>
            <div className="relative group">
              <Question className="size-8" />
              <Popover text={feature.popoverText} widthClass="w-96" />
            </div>
          </div>
        </td>

        {headers.map((plan, planIndex) => {
          const row = feature.rows[planIndex];
          const isCancel = row.label === "Cancel";

          return (
            <td className={`px-4 py-4 text-center ${isCancel ? "group" : ""}`} key={headers.label}>
              <div className="flex justify-center relative">
                {isCancel ? (
                  <>
                    <Cancel className="size-8 text-gray-900" />
                    <Popover text={row.popoverText} widthClass="w-64" />
                  </>
                ) : (
                  <SimpleCheck className="size-8 text-gray-900" />
                )}
              </div>
            </td>
          );
        })}
      </tr>
      <tr>
        <td colSpan={headers.length + 2} className="h-4" />
      </tr>
    </>
  );
}

Row.propTypes = {
  feature: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
};

export default React.memo(Row);
