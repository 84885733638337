import React, { memo } from "react";

const TableHeader = memo(({ headers }) => (
  <thead>
    <tr>
      <th className="py-8 pr-4" colSpan={2} />
      {headers.map(plan => (
        <th className="px-4 py-8 text-center" key={plan}>
          <h2 className="font-bold text-gray-200 text-xl whitespace-nowrap">{plan}</h2>
        </th>
      ))}
    </tr>
  </thead>
));

TableHeader.propTypes = {
  headers: Array.isRequired,
};

export default TableHeader;
