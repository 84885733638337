import React from "react";
import Item from "./Item";
import SeparatorSVG from "./SeparatorSVG";
import { features } from "./data";

const splitIndex = 3;

function Pricing() {
  return (
    <section className="py-12 bg-gray-50 sm:py-16 lg:py-20" id="pricing">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex items-center justify-center flex-col md:flex-row md:w-3/4 mx-auto">
            <h2 className="text-4xl font-bold text-gray-900">Everything You Need—No Limits, One Simple Price</h2>
          </div>
        </div>

        <div className="relative max-w-sm mx-auto mt-8 md:mt-12 md:max-w-6xl">
          <div className="absolute -inset-4">
            <div
              className="w-full h-full mx-auto opacity-30 blur-lg filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            />
          </div>

          <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
            <div className="p-6 md:px-10 md:py-9">
              <div className="grid items-center grid-cols-1 md:grid-cols-7 gap-y-9 md:gap-y-0">
                <div className="md:col-span-3 xl:pr-2 space-y-9">
                  {features.slice(0, splitIndex).map(feature => (
                    <Item key={feature} title={feature} />
                  ))}
                </div>

                <div>
                  <div className="hidden md:block">
                    <SeparatorSVG />
                  </div>
                  <div className="block md:hidden">
                    <SeparatorSVG mobile />
                  </div>
                </div>

                <div className="md:col-span-3 xl:pl-2 space-y-9">
                  {features.slice(splitIndex).map(feature => (
                    <Item key={feature} title={feature} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 text-center">
          <div className="flex items-end justify-center mt-10">
            <p className="text-lg font-bold text-gray-400 font-pj">$</p>
            <p className="text-6xl font-bold text-gray-900 font-pj">299</p>
            <p className="text-lg font-bold text-gray-400 font-pj">/month</p>
          </div>

          <a
            href="#signup"
            title="Get started for $299 only"
            className="relative inline-flex items-center justify-center px-8 py-3.5 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent mt-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-opacity-90 rounded-xl"
            role="button"
          >
            Get started for $299 only
          </a>

          <p className="mt-4 text-sm font-normal text-gray-600 font-pj">
            Best Price, Guaranteed—Find a Comparable Software for Less, and Fundview is Free!
          </p>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Pricing);
