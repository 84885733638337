import React, { useCallback, useState } from "react";
import Image from "next/image";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Close, Menu } from "@/assets/icons";
import { Fundview } from "@/assets/images";
import NavLink from "@/components/navlink/NavLink";

const navOptions = [
  { id: 1, href: "#whyFundview", label: "Why Fundview?" },
  { id: 2, href: "#useCases", label: "Uses Cases" },
  { id: 3, href: "#features", label: "Features" },
  { id: 4, href: "#pricing", label: "Pricing" },
  { id: 5, href: "#faq", label: "FAQs" },
];

function Navbar({ user }) {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <header className="py-4 md:py-6 fixed top-0 z-50 w-full bg-white shadow-md">
      <div className="container px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex-shrink-0">
            <NavLink
              href="/public"
              className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              label={<Image className="w-auto h-8" src={Fundview} alt="Logo" />}
            />
          </div>

          <div className="flex lg:hidden">
            <button type="button" className="text-gray-900" onClick={handleToggle} aria-expanded={expanded}>
              {!expanded ? <Menu /> : <Close />}
            </button>
          </div>

          <div className="hidden lg:flex lg:ml-16 lg:items-center lg:justify-center lg:space-x-10 xl:space-x-16">
            {navOptions.map(({ href, label }) => (
              <NavLink
                key={label}
                href={href}
                label={label}
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              />
            ))}
          </div>

          <div className="hidden lg:ml-auto lg:flex lg:items-center lg:space-x-10">
            {!isEmpty(user) && (
              <>
                <p className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                  {`Welcome, ${user?.user_metadata?.firstName}`}
                </p>
                <NavLink
                  key={10}
                  href="/dashboard"
                  target="_blank"
                  label="Go to Dashboard"
                  className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                />
              </>
            )}
            {isEmpty(user) && (
              <>
                <NavLink
                  key="login"
                  href="/login"
                  label="Customer Login"
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                />
                <NavLink
                  key="sign-up"
                  href="#signup"
                  label="Sign Up"
                  className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                />
              </>
            )}
          </div>
        </div>

        {expanded && (
          <div className="lg:hidden">
            <nav className="mt-4">
              <div className="space-y-1">
                {navOptions.map(({ href, label }) => (
                  <NavLink
                    key={href}
                    href={href}
                    label={label}
                    className="block px-4 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                  />
                ))}
                {!isEmpty(user) && (
                  <>
                    <NavLink
                      key="user"
                      href="#"
                      label={user?.firstName}
                      className="block px-4 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                    />
                    <NavLink
                      key="dashboard"
                      href="/dashboard"
                      label="Go to Dashboard"
                      className="block px-4 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                    />
                  </>
                )}
                {isEmpty(user) && (
                  <>
                    <NavLink
                      key="login"
                      href="/login"
                      label="Customer Login"
                      className="block px-4 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                    />
                    <NavLink
                      key="sign-up"
                      href="#signup"
                      label="Sign Up"
                      className="block px-4 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                    />
                  </>
                )}
              </div>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}

Navbar.propTypes = {
  user: PropTypes.object,
};

export default Navbar;
