import { Available, CostEffective, HeatMap, LoanPackaging, Parameters, SecureCollaboration } from "@/assets/icons";

export const features = [
	{
		title: "Lender Program and Parameter Updates in Real Time",
		description:
			"Fundview allows user to empower their lenders to update their loan programs and parameters directly within the platform. This ensures brokers always have the most accurate financing options without needing to manually track changes, saving time and improving deal execution.",
		icon: Parameters,
	},
	{
		title: "Intelligent Lender Matching & Advanced Search",
		description:
			"With Lender Heat Maps and Best-Fit Matching, brokers can instantly identify the most relevant lenders for each deal. Advanced filtering by loan size, asset type, and terms ensures precision in lender selection.",
		icon: HeatMap,
	},
	{
		title: "Seamless Loan Packaging & Document Management",
		description:
			"Fundview’s borrower and lender portals streamline the entire loan lifecycle, from automated borrower Needs Lists to real-time document tracking and loan package submissions. With centralized management and reduced manual follow-ups, brokers can increase efficiency and close deals faster.",
		icon: LoanPackaging,
	},
	{
		title: "Built for CRE Finance – Ready on Day 1",
		description:
			"Fundview is designed exclusively for CRE finance professionals, eliminating the inefficiencies of generic CRMs. With deal tracking, borrower and lender management, and real-time updates, brokers can start managing transactions immediately—no setup, no workarounds, just results.",
		icon: Available,
	},
	{
		title: "Secure & Role-Based Collaboration",
		description:
			"Manage team access with customizable roles, ensuring the right people have visibility over contacts, deals, and lender data while controlling confidentiality and data security within the platform.",
		icon: SecureCollaboration,
	},
	{
		title: "Cost-Effective, Transparent Pricing with No Add-Ons",
		description:
			"Fundview offers unlimited data, no add-on fees, and no commission splits—providing the lowest-cost CRE finance software on the market with full feature access from day one.",
		icon: CostEffective,
	},
];

