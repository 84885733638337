import React, { useCallback, useState } from "react";
import Script from "next/script";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import NumericFormat from "react-number-format";
import ConfettiExplosion from "react-confetti-explosion";
import { inputs } from "./inputs";
import { RECAPTCHA_ACTION_NAMES } from "@/utils/applicationConstants";
import { createDemoAccess } from "@/redux/actions/contact_form";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { contactFormValidationSchema } from "@/components/forms/contact_form/validator";
import { Button } from "@/components/button/button";

function Form() {
  const dispatch = useDispatch();
  const [startConfetti, setStartConfetti] = useState(false);
  const { isProcessing } = useSelector(state => state.contactForm);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(contactFormValidationSchema),
  });

  const handleResetForm = useCallback(() => {
    setStartConfetti(true);
    reset();

    setTimeout(() => {
      setStartConfetti(false);
    }, 6000);
  }, [reset]);

  const onSubmit = useCallback(
    data => {
      window.grecaptcha.enterprise
        .execute(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY, { action: RECAPTCHA_ACTION_NAMES.DEMO_FORM })
        .then(token => {
          dispatch(
            createDemoAccess(
              {
                ...data,
                page: RECAPTCHA_ACTION_NAMES.DEMO_FORM,
                token,
              },
              () => {
                sendAnalytics(ANALYTIC_EVENTS.DEMO_REQUESTED, { ...data });
                handleResetForm();
              },
            ),
          );
        });
    },
    [dispatch, handleResetForm],
  );

  return (
    <form className="mt-8 w-full" onSubmit={handleSubmit(onSubmit)}>
      {startConfetti && <ConfettiExplosion duration={5000} />}
      <Script
        id="google-recaptcha"
        strategy="lazyOnload"
        src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY}`}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-5 w-full">
        {inputs.map(field => (
          <div className={`col-span-1 w-full ${field.colSpan === 1 ? "sm:col-span-1" : "sm:col-span-2"}`}>
            <label htmlFor={field.label} className="text-base font-medium text-gray-900">
              {field.label}
            </label>
            <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
              {field.id === "phoneNumber" ? (
                <Controller
                  name={field.id}
                  control={control}
                  defaultValue=""
                  render={({ field: numberedField }) => (
                    <NumericFormat
                      defaultValue={field.defaultValue}
                      className="disabled:bg-gray-200 block w-full py-4 pl-3 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                      onValueChange={values => numberedField.onChange(values.value)}
                      getInputRef={numberedField.ref}
                      displayType="input"
                      type="text"
                      prefix="+1 "
                      placeholder="(999) 999 9999"
                      format="+1 (###) ### ####"
                      mask="_"
                    />
                  )}
                />
              ) : (
                <input
                  {...register(field.id)}
                  type={field.type || "text"}
                  placeholder={field.placeholder}
                  id={field.label}
                  disabled={isProcessing}
                  className="disabled:bg-gray-200 block w-full py-4 pl-3 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                />
              )}
              <p
                className={classNames("flex space-x-1 mt-2 text-left text-sm text-red-500", {
                  hidden: !errors[field.id]?.message,
                })}
              >
                <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1" aria-hidden="true" />
                {errors[field.id]?.message}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-5">
        <Button
          label="Sign up"
          type="submit"
          color="default"
          className="inline-flex items-center justify-center w-full px-4 py-4 text-lg font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-gradient-to-r from-fuchsia-600 to-blue-600 focus:outline-none hover:opacity-80 focus:opacity-80"
          isLoading={isProcessing}
        />
      </div>
    </form>
  );
}

export default Form;
